
import React, { forwardRef } from 'react';
import { useCore } from '../../core';

export const IsiContent = forwardRef((props, ref) => {
    const coreState = useCore();
    const { assets } = coreState;
    const { bottomIsiInView, isOpen } = props;
    return (
        <div ref={ref} className="drawer-content isi-content">


            {(!isOpen && !bottomIsiInView) && <p className="isi-subtitle isi-enter-animation" style={{ marginTop: 0 }}>
                IMPORTANT SAFETY INFORMATION
            </p>}

            <div className="flex">

                <div className="col left">
                    <div className="col right">
                        <p className="isi-subtitle isi-enter-animation">INDICATIONS</p>
                        <p className="isi-enter-animation mb-2">
                            COSENTYX<sup>&reg;</sup> (secukinumab) is indicated for the
                            treatment of moderate to severe plaque psoriasis (PsO) in patients 6
                            years and older who are candidates for systemic therapy or
                            phototherapy.
                        </p>
                        <p className='mb-2'>
                            COSENTYX is indicated for the treatment of active psoriatic
                            arthritis (PsA) in patients 2 years of age and older.
                        </p>
                        <p className='mb-2'>
                            COSENTYX is indicated for the treatment of adult patients with
                            active ankylosing spondylitis (AS).
                        </p>
                        <p className='mb-2'>
                            COSENTYX is indicated for the treatment of adult patients with
                            active non-radiographic axial spondyloarthritis (nr-axSpA) with
                            objective signs of inflammation.
                        </p>
                        <p className='mb-2'>
                            COSENTYX is indicated for the treatment of active
                            enthesitis-related arthritis (ERA) in patients 4 years of age and
                            older.
                        </p>
                        <p className='mb-2'>
                            COSENTYX is indicated for the treatment of adult patients with moderate to severe hidradenitis suppurativa (HS).
                        </p>
                    </div>
                    {(props.isOpen || bottomIsiInView) && (
                        <>
                            {/* <p className="mb-2">
                                COSENTYX is indicated for the treatment of adult patients with moderate to severe hidradenitis suppurativa (HS).
                            </p> */}
                            <p className="isi-subtitle isi-enter-animation" style={{ marginTop: 0 }}>
                                IMPORTANT SAFETY INFORMATION
                            </p>
                        </>
                    )}
                    <p className={`isi-subtitle isi-enter-animation`} style={{ marginTop: 5 }}>CONTRAINDICATIONS</p>
                    <p className="isi-enter-animation">
                        COSENTYX is contraindicated in patients with a previous serious
                        hypersensitivity reaction to secukinumab or to any of the
                        excipients in COSENTYX. Cases of anaphylaxis and angioedema have been reported
                        during treatment with COSENTYX.
                    </p>

                    <div className="isi-subtitle" style={{ marginTop: 10 }}>WARNINGS AND PRECAUTIONS</div>
                    <div className="isi-subtitle">Infections</div>
                    <p>
                        COSENTYX may increase the risk of infections. In clinical trials, a higher rate of infections was observed in COSENTYX treated subjects compared to placebo-treated subjects. In placebo-controlled clinical trials in subjects with moderate to severe PsO, higher rates of common infections, such as nasopharyngitis (11.4% versus 8.6%), upper respiratory tract infection (2.5% versus 0.7%) and mucocutaneous infections with candida (1.2% versus 0.3%) were observed in subjects treated with COSENTYX compared to placebo-treated subjects. A similar increase in risk of infection in subjects treated with COSENTYX was seen in placebo-controlled trials in subjects with PsA, AS and nr-axSpA. The incidence of some types of infections, including fungal infections, appeared to be dose-dependent in clinical trials.
                    </p>
                </div>

            </div>

            <p className='mt-2 mb-2'>
                In the postmarketing setting, serious bacterial, viral, and fungal opportunistic infections, and some fatal infections have been reported in patients receiving IL-17 inhibitors including COSENTYX. Cases of Hepatitis B virus reactivation have been reported.


            </p>
            <p className='mb-2'>

                Exercise caution when considering the use of COSENTYX in patients with a chronic infection or a history of recurrent infection. Instruct patients to seek medical advice if signs or symptoms suggestive of an infection occur. If a patient develops a serious infection, monitor the patient closely and discontinue COSENTYX until the infection resolves.

            </p>
            <p className='mb-2'>

                If signs of Hepatitis B virus reactivation occur, consult a hepatitis specialist. COSENTYX is not recommended for use in patients with active viral hepatitis.
            </p>
            <p className="isi-subtitle top-spacing">
                Pre-treatment Evaluation for Tuberculosis
            </p>
            <p>
                Evaluate patients for tuberculosis (TB) infection prior to initiating
                treatment with COSENTYX. Avoid administration of COSENTYX to patients
                with active TB infection. Initiate treatment of latent TB prior to
                administering COSENTYX. Consider anti-TB therapy prior to initiation
                of COSENTYX in patients with a past history of latent or active TB in
                whom an adequate course of treatment cannot be confirmed. Monitor
                patients closely for signs and symptoms of active TB during and after
                treatment.
            </p>
            <p className="isi-subtitle top-spacing">Inflammatory Bowel Disease</p>
            <p className='mb-2'>
                Inflammatory Bowel Disease (IBD) exacerbations, in some cases serious and/or leading to discontinuation of COSENTYX, occurred in COSENTYX treated subjects during clinical trials in PsO, PsA, AS, nr-axSpA, and HS. In adult subjects with HS, the incidence of IBD was higher in subjects who received COSENTYX 300 mg every 2 weeks (Ulcerative Colitis [UC] 1 case, EAIR 0.2/100 subject-years; Crohn’s Disease [CD] 1 case, EAIR 0.2/100 subject-years) compared to subjects who received COSENTYX 300 mg every 4 weeks (IBD 1 case, EAIR 0.2/100 subject-years). In addition, new onset IBD cases occurred in subjects treated with COSENTYX in clinical trials. In an exploratory trial in 59 subjects with active Crohn’s disease [COSENTYX is not approved for the treatment of Crohn’s disease], there were trends toward greater disease activity and increased adverse reactions in subjects treated with COSENTYX as compared to placebo-treated subjects.
            </p>
            <p>
                Exercise caution when prescribing COSENTYX to patients with IBD. Patients treated with COSENTYX should be monitored for signs and symptoms of IBD.
            </p>
            <p className="isi-subtitle top-spacing">Eczematous Eruptions</p>
            <p style={{ marginBottom: 10 }}>
                In postmarketing reports, cases of severe eczematous eruptions, including atopic dermatitis-like eruptions, dyshidrotic eczema, and erythroderma, were reported in patients receiving COSENTYX; some cases resulted in hospitalization. The onset of eczematous eruptions was variable, ranging from days to months after the first dose of COSENTYX.
            </p>
            <p style={{ marginBottom: 10 }}>
                Treatment may need to be discontinued to resolve the eczematous eruption. Some patients were successfully treated for eczematous eruptions while continuing COSENTYX.
            </p>
            <p className="isi-subtitle top-spacing">Hypersensitivity Reactions</p>
            <p style={{ marginBottom: 10 }}>
                Serious hypersensitivity reactions including anaphylaxis, angioedema, and urticaria have been reported in COSENTYX treated subjects in clinical trials and in the post-marketing setting. If an anaphylactic or other serious allergic reaction occurs, immediately discontinue administration of COSENTYX and initiate appropriate therapy.
            </p>
            <p>
                The removable caps of the COSENTYX Sensoready<sup style={{ fontSize: '1rem', top: 2 }}>®</sup> pen and the COSENTYX 1
                mL and 0.5 mL prefilled syringes contain natural rubber latex, which
                may cause an allergic reaction in latex-sensitive individuals. The
                safe use of the COSENTYX Sensoready pen or prefilled syringe in
                latex-sensitive individuals has not been studied.
            </p>
            <p className="isi-subtitle top-spacing">Immunizations</p>
            <p>
                Prior to initiating therapy with COSENTYX, consider completion of all
                age-appropriate immunizations according to current immunization
                guidelines. COSENTYX may alter a patient's immune response to live
                vaccines. Avoid use of live vaccines in patients treated with
                COSENTYX.
            </p>
            <p className="isi-subtitle top-spacing">
                MOST COMMON ADVERSE REACTIONS
            </p>
            <p>
                Most common adverse reactions (&gt;1%) are nasopharyngitis, diarrhea,
                and upper respiratory tract infection.
            </p>
            <p className='top-spacing'>
                Please see full <a className="isi-link" target="_blank" rel="noreferrer" href="https://www.novartis.com/us-en/sites/novartis_us/files/cosentyx.pdf">Prescribing Information</a>, including{" "}
                <a className="isi-link" target="_blank" rel="noreferrer" href="https://www.novartis.com/us-en/sites/novartis_us/files/cosentyx_pmg.pdf">Medication Guide</a>.
            </p>
            <footer
                className="flex row"
            >
                <div className="col flex isi-bottom-left-flex-align">
                    <img
                        src={assets.NovartisLogo.url}
                        alt="novartis"
                        style={{ width: 150, marginBottom: 20 }}
                    />
                    <small className="novo isi-bottom-left-margin">Novartis Pharmaceuticals Corporation</small>
                    <small className="isi-bottom-left-margin">East Hanover, New Jersey 07936-1080</small>
                </div>
                <small className="col -mr-1">&copy; 2024 Novartis</small>
                <small className="col -mr-1">10/24</small>
                <small className="col -mr-1">FA-11241407</small>
            </footer>
        </div>
    )

})